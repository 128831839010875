.appBar {
  border-radius: 0.5px;
  height: 64px;
  box-shadow: 2;
  opacity: 1;
  z-index: 1;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
}

.leftSection {
  margin-left: 0.5em;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1vw;
}

.menuButton {
  margin: 0.45em;
}

.logoContainer {
  height: 96px;
  width: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.logo {
  height: 8rem;
  width: 12rem;
  opacity: 1;
}

.companyDivisionContainer {
  display: flex;
  gap: 8px;
  align-items: center;
}

.selectControl {
  min-width: 80px !important;
}

.selectText {
  font-weight: 500 !important;
  font-size: 1rem !important;
  height: 40px !important;
}

.selectText :global(.MuiSelect-select) {
  padding: 8px 14px !important;
}

.selectLabel {
  font-weight: 500 !important;
  font-size: 1rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.selectMenuItem {
  color: rgba(0, 0, 0, 0.87) !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}

.rightSection {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 0;
  width: 50%;
  height: 100%;
  margin-right: 2em;
}

.themeButton {
  margin-right: 16px;
}

.userMenu {
  margin-top: 45px;
}

.menuTypography {
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
}

.mobileMenuItem {
  font-weight: 600;
  font-size: 1rem;
}

.formControlMobile {
  margin-top: 0.5em;
  width: 80px;
}

.formControl {
  width: 80px;
}

.mobileMenuBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
}

.userLink {
  text-decoration: none !important;
  font-weight: 600 !important;
}

.userMenuTypography {
  text-align: center;
  font-weight: 600;
}

.avatarButton {
  padding: 0;
}

/* Force MUI styles override */
.selectInput {
  padding: 8px 14px !important;
}
